.contact-container {
    width: 75%;
    margin: 0 auto;
    padding: 3rem;
  }

  .contact-image {
    width: 40vw;
    height:auto;
    min-width: 200px;
    margin-left: 2rem;
  }
  
  @media (max-width: 1000px) {
    .contact-image {
      display: none;
    }
  }