.bold {
    text-emphasis: bold;
    font-weight: bold;
}

.rate-image {
    width: 15vw;
    height:auto;
    min-width: 200px;
    margin-left: 2rem;
  }
  
  @media (max-width: 1000px) {
    .rate-image {
      display: none;
    }
  }
