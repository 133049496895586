.bold {
    text-emphasis: bold;
    font-weight: bold;
}

@media (max-width: 1000px) {
    .adaptiveEducation {
        display: flex;
        flex-direction: column;     
        justify-content: center;   
    }
  }
  @media (min-width: 1000px) {
    .adaptiveEducation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
  }

