@import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin&display=swap');

.header {
  height: 10vh;
  width: 100%;
  background-color: rgb(221, 231, 234);
}

.header-text-name {
  font-weight:600;
  font-size: large;
  color: rgb(31, 32, 33)
}

.a-container {
  margin-left: auto;
}

.a-container > a {
  font-size: large;
  color: rgb(31, 32, 33);
  font-weight: 600;
  text-decoration: none;
}

.a-container > a:hover {
  text-decoration: underline;
}

.text {
  font-family: 'Kaisei Tokumin', serif;
}

.hero {
  /* background-size: 100vw; */
  width: 100%;
  text-align: center;
  padding-top: 1%;
  max-height: 25vw;
}

.heroText {
  
  /* width: 100%;
  height: 100%; */
  /* font-size: x-large; */
  color: rgb(67, 74, 77);
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.main {
  width: 75%;
  height: 100vh;
  margin: 0 auto;
}

.section {
  padding: 5rem;
}

.work-image {
  width: 30vw;
  padding-right: 2rem;
}

@media (max-width: 1000px) {
  .about-image {
    display: none;
  }
}
@media (min-width: 1000px) {
  .about-image {
    width: 15vw;
    padding-right: 2rem;
    float: "left"; 
    display: inline;
  }
}